import Mrh from "./f/mrh/Mrh";
import Pz from "./f/pz/Pz";

function App() {
  return (
    <div>
      <Pz />
      <Mrh />
    </div>
  );
}

export default App;
